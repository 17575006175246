<template>
  <div class="main">
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="main_center">
      <div class="back" @click="back">
        <van-image
          width="9px"
          height="15px"
          :src="require('@/assets/cls/arrow-l.png')"
        />
        <span>{{ $t("withdraw.return") }}</span>
      </div>
      <div class="form_input">
        <div class="input_item">
          <div class="label">{{$t("accounts.miningNum")}}</div>
          <input type="text" v-model="coinType" style="background:#EFF0FF;" readonly/>
        </div>
        <div class="input_item" style="margin-top: 20px">
          <div class="label">{{$t("accounts.AddDraft")}}ID</div>
          <input type="text" :placeholder="$t('accounts.determine')" v-model="toUserid"/>
        </div>
        <div class="money">{{ $t("withdraw.Withdrawal") }}</div>
        <van-field
          v-model="amount"
          type="number"
          :placeholder="
            i == 1
              ? `${$t('withdraw.current')}:${USDT.amount}USDT`
              : `${$t('withdraw.current')}:${TOKEN.amount}UNA`
          "
          class="input"
          :border="false"
          @input="onInput"
        >
          <template #extra>
            <div class="all">
              <div class="name">{{ i == 1 ? "USDT" : "UNA" }}</div>
              <div class="line"></div>
              <div class="all_btn" @click="allHandle">
                {{ $t("withdraw.all") }}
              </div>
            </div>
          </template>
        </van-field>
        <div class="lowest">
          <span style="margin-right: 27px"
            >{{ $t("withdraw.minimum") }}：{{ minNum}}</span
          >
        </div>
        <div style="margin-bottom: 199px" class="zq">
          <van-button
            :hairline="false"
            :round="true"
            color="#8f92fa"
            style="width: 100%;"
            @click="withDrawHandle"
            >{{ $t("accounts.Addmining") }}</van-button
          >
        </div>
      <p class="source" @click="toRecord">{{ $t("accounts.card") }}</p>

      </div>
    </div>
        <asidePopup v-if="asideFlag" @close="noShow" :shows='asideFlag'></asidePopup>
  </div>
</template>

<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components:{asidePopup},
  data() {
    return {
      asideFlag: false,
      i: 2,
      amount: null,
      TOKEN: {},
      coinType:'UNA',
      toUserid:null,
      minNum:null,
    };
  },
  created  () {
    this.$store.dispatch("getSetting"); //网站配置
    let setting = JSON.parse(this.$store.state.setting);
    setting.forEach(item => {
      if(item.key == 'transfer_min_num'){
        this.minNum = item.value
      }
    })
    // this.getmyEarn();
    this.getMyEarn();
  },
  methods: {
    withDrawHandle(){
      if(!this.toUserid){
        this.$toast(this.$t('accounts.Successfully'));
        return
      }
      if(!this.amount){
        this.$toast(this.$t('accounts.quantity'));
        return
      }
      this.$axios.post(this.$api.userWithdraw,{
        to_userid:this.toUserid,
        amount:this.amount
      }).then(res => {
        this.toUserid = null
        this.amount = null
        console.log(res)
        this.$toast(this.$t('accounts.income'));
      })
    },
    noShow(){
      this.asideFlag = false
    },
    asideFlagFun(){
      this.asideFlag = true
    },
      toRecord(){
          this.$router.push('/accountsRecord')
      },
    back() {
      this.$router.back();
    },
    // 资产列表
    getMyEarn() {
      this.$axios.get(this.$api.myEarn, {}).then((res) => {
        this.USDT = res.data.USDT;
        this.TOKEN = res.data.TOKEN;
      });
    },
    // 输入框全部按钮
    allHandle() {
      if (this.i == 1) {
        this.amount = this.USDT.amount;
      } else {
        this.amount = this.TOKEN.amount;
      }
    },
    onInput(e) {
      if (this.amount.length != 0) {
        this.disabledOk = false;
      } else {
        this.disabledOk = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  .back {
    margin: 13px 0 28px;
    display: flex;
    align-items: center;
    span {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-left: 6px;
    }
  }
  .main_center {
    width: 100%;
    padding: 5px 10px;
    .title {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .form_input {
      width: 100%;
      margin-top: 32px;
      .input_item {
        .label {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        input {
          border: 1px solid #999999;
          border-radius: 5px;
          width: 100%;
          height: 37px;
          padding: 10px;
          box-sizing: border-box;
          margin-top: 6px;
        }
        .inputBox {
          width: 100%;
          height: 37px;

          display: flex;
          input {
            border-radius: 10px;
            width: 70%;
            height: 37px;
            padding: 10px;
            box-sizing: border-box;
            margin-top: 6px;
          }
        }
      }
    }
  }
  .money {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 6px;
    margin-top: 20px;
  }
  .input {
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 10px 10px 10px 0;
    .all {
      display: flex;
      align-items: center;

      .name {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      .line {
        height: 16px;
        border-right: 1px solid #999999;
        margin: 0 11px;
      }
      .all_btn {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #8d91f9;
      }
    }
  }
  .lowest {
    margin: 5px 0 74px;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
  .list {
    .cell-title {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    /deep/.van-cell::after {
      border-bottom: 1px solid #eaeaea;
    }
  }
}
.source {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #7857fe;
}
.zq{
  border: 1px solid #000;
  border-radius:5px;
  background: linear-gradient(90deg, #B79AFA, #8867FE);;
  button{
    border-radius:5px !important;
    border:none !important;
    background: none !important; 
  }
}
</style>